<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
               @submit.native.prevent>
        <el-form-item label="省:" label-width="35px" class="w-170">
          <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200">
          <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                       :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易开始时间：" label-width="110px" style="margin-left:50px;">
          <el-date-picker :picker-options="pickerOptionsStart" style="margin-right: 10px"  popper-class="no-atTheMoment" @change="birthdayChange" v-model="tableCreate_time"  value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="交易结束时间：" label-width="110px">
          <el-date-picker :picker-options="pickerOptionsEnd" style="margin-right: 10px"  popper-class="no-atTheMoment" @change="birthdayChange1" v-model="tableend_time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="关联订单号:" label-width="90px">
          <el-input v-model="table.where.out_trade_no" placeholder="请输入关联订单号" clearable />
        </el-form-item>
        <el-form-item label="支付方式:" label-width="80px">
          <el-select v-model="table.where.payment" placeholder="请选择" clearable class="ele-fluid">
            <el-option label="全部支付" value="all" />
            <el-option label="微信支付" value="wxpay" />
            <el-option label="云闪付" value="unionpay" />
            <el-option label="账户余额支付" value="balance" />
          </el-select>
        </el-form-item>
        <el-form-item label="收支类型:" label-width="80px">
          <el-select v-model="table.where.payment_name" placeholder="请选择" clearable class="ele-fluid">
            <el-option label="全部类型" value="all" />
            <el-option label="收入" value="收入" />
            <el-option label="支出" value="支出" />
          </el-select>
        </el-form-item>
        <el-form-item label="交易备注:" label-width="90px">
          <el-input v-model="table.where.subject" placeholder="请输入交易备注" clearable />
        </el-form-item>
<!--        <el-form-item label="订单类型:" label-width="80px">-->
<!--          <el-select v-model="table.where.business_type" placeholder="请选择" clearable class="ele-fluid">-->
<!--            <el-option label="出行订单" value="1" />-->
<!--            <el-option label="叫代驾" value="2" />-->
<!--            <el-option label="顺风车" value="3" />-->
<!--            <el-option label="跑腿" value="4" />-->
<!--            <el-option label="商超" value="5" />-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item style="margin-left: 20px;">
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">
            搜索</el-button>
          <el-button @click="(table.where={source:2})&&$refs.table.reload()">重置</el-button>
          <download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="pay_log/exportPayLogExcel" :params="exportOrderParams()" v-if="permission.includes('sys:finance_order_car:export')" />
        </el-form-item>

      </el-form>

      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row :stripe=true>
        <template >
          <el-table-column prop="set_area" label="所属区域" show-overflow-tooltip min-width="160" />
          <el-table-column  label="业务单号" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">
              {{ row.business_type >= 97 ? '/':row.log_no}}
            </template>
          </el-table-column>
          <el-table-column  label="资金流水号" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">
              {{ row.business_type >= 97 ? '/':row.trade_no}}
            </template>
          </el-table-column>
          <el-table-column label="交易时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.pay_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column  label="关联订单号" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">
              {{ row.business_type >= 97 ? '/':row.out_trade_no}}
            </template>
          </el-table-column>
          <el-table-column prop="buyer_user" label="对方账号" show-overflow-tooltip min-width="160" />
          <el-table-column prop="paymentname" label="支付方式" show-overflow-tooltip min-width="140" />
          <el-table-column prop="payment_name" label="收支类型" show-overflow-tooltip min-width="120" />
          <el-table-column prop="total_price" label="收支金额" show-overflow-tooltip min-width="160" />
<!--          <el-table-column prop="charge" label="手续费" show-overflow-tooltip min-width="160" />-->
          <el-table-column  label="业务类型" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">
              {{ row.type_id == 1 ? '在线交易':'扣除交易手续费'}}
            </template>
          </el-table-column>
<!--          <el-table-column prop="business_type_name" label="业务类型" show-overflow-tooltip min-width="160" />-->
          <el-table-column prop="subject" label="交易备注" show-overflow-tooltip min-width="160" />

<!--          <el-table-column prop="user_name" label="用户名称" show-overflow-tooltip min-width="160" />-->
<!--          <el-table-column prop="user_mobile" label="用户手机" show-overflow-tooltip min-width="160" />-->
          <!--<el-table-column prop="store_name" label="司机/商户名称" show-overflow-tooltip min-width="160" />-->
          <!--<el-table-column prop="store_phone" label="司机/商户手机" show-overflow-tooltip min-width="160" />-->

          <!--<el-table-column prop="user_id" label="用户id" show-overflow-tooltip min-width="180" />-->
<!--          <el-table-column label="订单状态" show-overflow-tooltip min-width="110">-->

<!--            <template slot-scope="{row}">-->
<!--              {{ row.status ==0 ? '待支付' : row.status == 1 ? '已支付' :  '已关闭'}}-->
<!--            </template>-->
<!--          </el-table-column>-->

          <!--<el-table-column label="transfer_status" show-overflow-tooltip min-width="160"	/>-->

          <!-- <el-table-column prop="user_name" label="用户昵称" show-overflow-tooltip min-width="140" />
          <el-table-column prop="user_phone" label="用户手机号" show-overflow-tooltip min-width="120" /> -->
          <!-- <el-table-column prop="order_price" label="订单金额(元)" show-overflow-tooltip min-width="120" />
          <el-table-column prop="driver_get_money" label="实际到账(元)" show-overflow-tooltip min-width="120" /> -->
<!--          <el-table-column prop="subject" label="订单名称" show-overflow-tooltip min-width="160" />-->
<!--          <el-table-column prop="return_msg" label="支付通知返回信息" show-overflow-tooltip min-width="160" />-->
          <!--<el-table-column prop="pay_time" label="支付时间" show-overflow-tooltip min-width="160" />
            <template slot-scope="{row}">{{ row.pay_time*1000 | toDateString }}</template>
          </el-table-column>-->
          <!--<el-table-column prop="close_time" label="关闭时间" show-overflow-tooltip min-width="160" />-->
<!--          <el-table-column prop="add_time" label="添加时间" show-overflow-tooltip min-width="160" />-->

          <!--					<el-table-column prop="status_name" label="订单状态" show-overflow-tooltip min-width="120" />
          -->
        </template>
      </ele-data-table>
    </el-card>
    <!-- 查看订单详情 -->
    <el-dialog v-dialogDrag title="查看订单详情" :visible.sync="dialogTableVisibleCheck"  :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="checkForm" label-width="140px">
          <div style="display: flex;">
            <div style="width:100%;box-sizing: border-box;padding: 0 50px 0 10px;">
              <el-form-item label="发单时间：" prop="create_time">
                {{ checkForm.create_time*1000 | toDateString }}
              </el-form-item>
              <el-form-item label="订单号：" prop="order_id">
                {{checkForm.order_id}}
              </el-form-item>
              <el-form-item label="订单类型：" prop="type_name">
                {{checkForm.type_name}}
              </el-form-item>
              <el-form-item label="发单起点所在地：" prop="place_address">
                {{checkForm.place_address}}
              </el-form-item>
              <el-form-item label="用户语音：" prop="audio_length">
                <div class="charRecord-leftItem" style="margin-top: 0;line-height: 1;    transform: rotate(180deg);">
                  <div class="charRecord-title2 p-10 bgGreen"
                       style=""
                       @click="handlePlay(checkForm.audio)"
                       v-if="isPlay==true "
                  >
                    <audio class="pasuVideo" ref="middleindex" :src="checkForm.audio">您的浏览器不支持</audio>
                    <div class="lvoide1">
                      <img style="padding-right: 0px;padding-left: 30px;" src="../../../assets/images/yuyin-ico.png">
                    </div>
                  </div>
                  <div class="charRecord-title2 p-10 bgGreen"
                       v-if="isPlay==false">
                    <audio class="pasuVideo" :src="checkForm.audio"
                           ref="middleindex">您的浏览器不支持</audio>
                    <div class="lvoide1">
                      <img style="padding-right: 0px;padding-left: 30px;" src="../../../assets/images/yuyin-gif.gif">
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="接单司机：" prop="driver_name">
                {{checkForm.driver_name}}
              </el-form-item>
              <el-form-item label="司机手机号：">
                {{checkForm.driver_phone}}
              </el-form-item>
              <el-form-item label="接单时间：" prop="receive_time">
                {{ checkForm.receive_time*1000 | toDateString }}
              </el-form-item>
              <!-- <el-form-item label="完成时间：" prop="complete_time">
                {{ checkForm.complete_time*1000 | toDateString }}
              </el-form-item> -->
              <el-form-item label="支付方式：" prop="payment">
                线下支付
              </el-form-item>
              <el-form-item label="订单状态：" prop="status_name">
                {{checkForm.status_name}}
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>
<!--<link rel="stylesheet" href="https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css" />-->

<script src="https://webapi.amap.com/maps?v=1.4.13&key=645cd97a39f5c47bafce4a316785f342"></script>
<script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script>
<script>
import download from '@/components/Download/index'
import {
  mapGetters
} from "vuex";

export default {
  name: "SysUser",
  components: {
    download
  },
  data() {
    return {
      isPlay: true,
      tableCreate_time: '', //搜索选中时间戳
      tableend_time:'',
      bgWidth: '',
      aa: true,
      ampDisplay: false,
      dialogVisibleAdd: false,
      gStar: [0, 0, 0, 0, 0],
      gStartext: [false, false, false, false, false],
      charList: [],
      table: {
        url: '/pay_log/pay_log_list',
        where: {}
      }, // 表格配置
      choose: [], // 表格选中数据
      editForm: {}, // 表单数据
      checkForm: {},
      editRules: { // 表单验证规则
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },{
          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          message: '请输入正确的手机号',
          trigger: 'blur',
        }],
        name: [{
          required: true,
          message: '请输入昵称',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '请输入性别，1男2女',
          trigger: 'blur'
        }],
        home: [{
          required: true,
          message: '请输入家乡名称，对应aid',
          trigger: 'blur'
        }],

        money: [{
          required: true,
          message: '请输入余额',
          trigger: 'blur'
        }],

        losemoney: [{
          required: true,
          message: '请输入已提现/使用余额',
          trigger: 'blur'
        }],

      },
      dialogTableVisibleCheck: false,
      dialogTableVisibleChatRecord: false,
      provArr: [],
      time:[],
      cityArr: [],
      districtArr: [],
      audioArr: [],
      urgentList: [],
      imageUrl: false,
      headImg: '',
      zoom: 12,
      center: [104.07, 30.67],
      ampSrc: '',
      driverInfo: {},
      orderInfo: {},
      audio: '',
      pickerOptionsStart: {
        disabledDate: time => {
          let endDateVal = this.tableend_time;
          if (endDateVal) {
            return time.getTime() > new Date(endDateVal).getTime();
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          let beginDateVal = this.tableCreate_time;
          if (beginDateVal) {
            return (
                time.getTime() <
                new Date(beginDateVal).getTime()
            );
          }
        }
      },
    }
  },
  created() {
    this.$http.get('/common/province_list').then(res => {
      var data = JSON.parse(res.data)
      this.provArr = data
    })

    var url = this.$route.query.orderId
    if (url != undefined && url != '') {
      this.dialogTableVisibleChatRecord = true
      this.getChat(url)
    } else {
      this.dialogTableVisibleChatRecord = false
    }
  },
  computed: {
    ...mapGetters(["permission"]),
  },

  mounted() {},
  methods: {
    birthdayChange(){
      //var time = [];
//				if(this.table.where.create_time.length >0){
      this.time[0]=this.tableCreate_time
      this.table.where.create_time = this.time;
//				}else{
//					(this.table.where.create_time).unshift(this.tableCreate_time)
//				}
      //console.log(this.tableCreate_time)
      //console.log(this.table.where.create_time)
    },
    birthdayChange1(){
      //if(this.table.where.create_time.length >0){
      this.time[1]=this.tableend_time
      this.table.where.create_time = this.time;
      //console.log(this.table.where.create_time)

//				}else{
//					(this.table.where.create_time).push(this.tableend_time)
//				}
//				(this.table.where.create_time).push(this.tableend_time)
    },
    // 导出数据参数
    exportOrderParams() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return {
        ids: selectionIds,
        ...query
      }
    },
    hanlePicture(src) {
      this.ampDisplay = true
      this.ampSrc = src
    },
    handleClose() {
      this.ampDisplay = false
    },
    closeDialog() {
      var middlePlay = document.querySelectorAll('.pasuVideo')
      for (let i = 0; i < middlePlay.length; i++) {
        middlePlay[i].pause()
        middlePlay[i].currentTime = 0
      }
    },
    handlePlay(url, id, index) {
      let that = this
      var middlePlay = document.querySelectorAll('.pasuVideo')
      middlePlay[0].pause()
      middlePlay[0].currentTime = 0
      var middle = 'middleindex'
      middlePlay = this.$refs[middle]
      middlePlay.play()
      that.isPlay = false
      middlePlay.addEventListener('ended', function() {
        that.isPlay = true
      })


    },
    dropClick(command, row) {
      if (command === 'remove') { //删除
        this.remove()
      } else if (command === 'chat') { //紧急联系人
        this.handleChat(row)
      } else if (command === 'getChat') { //聊天记录
        this.getChat(row)
      }
    },
    save() {},
    // 聊天记录
    getChat(id) {
      const loading = this.$loading({
        lock: true,
        target: document.querySelector(".records")
      });
      this.$http.post('/order/chat_log', {
        id: id
      }).then(res => {
        var data = res.data
        loading.close()
        data.log.forEach(function(item) {
          item.isTimeShow = 1
          if (item.content.S == 21) {
            item.isPlay = true
            if (item.content.P.LONG == 1) {
              item.bgWidth = '8rem'
            }
            item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
          }
          if (item.content.S == 26) {
            item.content.P.POINT = item.content.P.POINT.split(',')
            //console.log(item.content.P.POINT)

          }
        });
        for (var i = 0; i < data.log.length; i++) {
          for (var j = i + 1; j < data.log.length - 1; j++) {
            if (data.log[i].create_time == data.log[j].create_time) {
              data.log[j].isTimeShow = 0 //不显示时间
            } else {
              data.log[j].isTimeShow = 1
            }
          }
        }
        this.charList = data.log
        this.driverInfo = data.driver
        this.orderInfo = data.order_info
      })

    },
    handleChat(row) {
      this.dialogTableVisibleChatRecord = true
      this.getChat(row.id)
    },
    /***选择省**/
    handleChangeProv(e) {
      /** 获取被选省份的pid**/
      var pid = ''
      this.provArr.forEach(function(item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        var data = JSON.parse(res.data)
        this.cityArr = data
        // //console.log(this.cityArr)
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''
      })
    },
    /***选择市**/
    handleChangeCity(e) {
      if(e){
        /** 获取被选市的cid**/
        var cid = ''
        this.cityArr.forEach(function(item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          var data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      }else{
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },
    /***查看用户信息**/
    handlecheck(row) {
      this.dialogTableVisibleCheck = true
      this.checkForm = row;
    },

    /*** 刪除(批量刪除)*/
    remove(row) {
      if (!row) { // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的订单吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('/order/delete', {
            id: ids
          }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else { // 单个删除
        const loading = this.$loading({
          lock: true
        });
        this.$http.post('/order/delete', {
          id: row.id
        }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    },
    /*** 拉入黑名单*/
    order_status_update(row) {
      this.$confirm('确定完成此订单?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true
        });
        this.$http.post('/order/order_status_update', {
          id: row.id
        }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }).catch(() => 0);
    },
    exportExcel() {
      let info = JSON.parse(JSON.stringify(this.table.where));
      //console.log('order data export');
      this.$http
          .get("/order/exportExcel", info)
          .then((res) => {
            let data = res.data;
            if (data.code == 0) {
              // 下载文件
              window.location.href = data.data;
              this.$message({
                type: "success",
                message: "导出成功",
              });
            } else {
              this.$message.error("导出失败");
            }
          })
    },
  }
}
</script>

<style scoped>
.bgGreen {
  background: #89B929 !important;
  color: #fff;
}

.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

.el-form-item-label {
  width: 20%;
  text-align: left
}

.disCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mr-10 {
  margin-right: 10px;
}

.el-date-editor /deep/.el-input,
.el-date-editor /deep/.el-input__inner {
  width: 100% !important;
}

/deep/.el-dialog {
  margin-top: 60px !important;
  max-width: 1200px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #fff;
  height: 160px;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.smsInner{
  border-bottom: 1px solid #ddd;
  padding-bottom: 14px;
}
</style>
